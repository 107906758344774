import React from 'react'

const CloseIcon = ({
  size = 32,
  style = {
    fill: 'none',
    stroke: '#44337a',
    strokeLinecap: 'round',
    strokeMiterlimit: 10,
    strokeWidth: '32px'
  },
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 512 512"
    style={style}
    {...props}
  >
    <title>Close</title>
    <line x1="368" y1="368" x2="144" y2="144" />
    <line x1="368" y1="144" x2="144" y2="368" />
  </svg>
)

export default CloseIcon
