import React, { useContext, useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import useChannelUpdatedSubscription from 'hooks/subscriptions/useChannelUpdated'
import CurrentUserContext from 'components/CurrentUserContext'
import WidgetContext from 'components/WidgetContext'
import useReadMessageMutation from 'hooks/mutations/useReadMessage'

const CHANNEL_QUERY = gql`
  query ChannelQuery($channelId: String!) {
    channel(channelId: $channelId) {
      id
      messages {
        id
        message
        createdAt
        userId
      }
    }
  }
`

const orderMessages = messages =>
  messages.sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt))

const bubbleColor = ({ id: userId }, { id: currentUserId }) =>
  userId === currentUserId ? 'bg-blue-200 self-end' : 'bg-green-200 self-start'

const Messages = ({ channelId }) => {
  const { setOpen } = useContext(WidgetContext)
  const { data: { channel = {} } = {}, error } = useQuery(CHANNEL_QUERY, {
    variables: {
      channelId
    },
    pollInterval: 60000,
    errorPolicy: 'all'
  })
  const { currentUser } = useContext(CurrentUserContext)
  const [readMessage] = useReadMessageMutation()
  const [lastReadMessageId, setLastReadMessageId] = useState(null)

  useEffect(() => {
    if (error) {
      setOpen(false)
    }
  }, [error])

  const { messages = [] } = channel || {}

  useEffect(() => {
    if (messages[0] && messages[0].id && lastReadMessageId === messages[0].id) {
      readMessage(channelId)
      setLastReadMessageId(messages[0].id)
    }
  })

  useChannelUpdatedSubscription(channelId)

  return (
    <div className="flex-1 overflow-y-scroll">
      {orderMessages(messages).map(({ id, message, userId }) => (
        <div className="flex flex-col mb-2" key={id}>
          <span
            className={`rounded-full py-2 px-4 ${bubbleColor(
              { id: userId },
              currentUser
            )}`}
          >
            {message}
          </span>
        </div>
      ))}
    </div>
  )
}

export default Messages
