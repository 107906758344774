import React, { createContext, useState } from 'react'

const WidgetContext = createContext()

export const WidgetProvider = ({ children }) => {
  const [open, setOpen] = useState(false)

  return (
    <WidgetContext.Provider
      value={{
        open,
        setOpen
      }}
    >
      {children}
    </WidgetContext.Provider>
  )
}

export default WidgetContext
