import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loader from 'components/Loader'
import Messages from 'components/Messages'
import PaperPlaneIcon from 'components/icons/PaperPlane'
import useJoinChannelMutation from 'hooks/mutations/useJoinChannel'
import useSendMessageMutation from 'hooks/mutations/useSendMessage'

const Messenger = ({ handleClose }) => {
  const { appId } = useParams()
  const [message, setMessage] = useState('')
  const [
    joinChannelMutation,
    { data: { joinChannel } = {}, loading }
  ] = useJoinChannelMutation()
  const [sendMessage] = useSendMessageMutation()

  useEffect(() => {
    joinChannelMutation(appId)
  }, [appId])

  if (loading || !joinChannel) {
    return <Loader />
  }

  return (
    <div className="h-messenger w-full flex flex-col bg-white p-4 mb-4 rounded-md shadow border">
      <Messages channelId={joinChannel.id} />
      <form
        className="flex mt-4"
        onSubmit={e => {
          e.preventDefault()
          sendMessage({ channelId: joinChannel.id, message })
          setMessage('')
        }}
      >
        <input
          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
          type="text"
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
        <button className="ml-4 flex items-center justify-center">
          <PaperPlaneIcon
            size={20}
            style={{
              fill: 'none',
              stroke: '#000',
              strokeLinecap: 'round',
              strokeMiterlimit: 10,
              strokeWidth: '32px'
            }}
          />
        </button>
      </form>
    </div>
  )
}

export default Messenger
