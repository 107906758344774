import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const READ_MESSAGE = gql`
  mutation ReadMessage($channelId: String!) {
    readMessage(channelId: $channelId)
  }
`

const useReadMessageMutation = options => {
  const [mutate, result] = useMutation(READ_MESSAGE, options)

  const readMessage = channelId =>
    mutate({
      variables: {
        channelId
      }
    })

  return [readMessage, result]
}

export default useReadMessageMutation
