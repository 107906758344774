import gql from 'graphql-tag'
import { useSubscription } from '@apollo/react-hooks'

export const CHANNEL_UPDATED = gql`
  subscription ChannelUpdatedSubscription($channelId: String!) {
    channelUpdated(channelId: $channelId) {
      id
      messages {
        id
        message
        createdAt
        userId
      }
    }
  }
`

const ChannelUpdatedSubscription = channelId =>
  useSubscription(CHANNEL_UPDATED, {
    variables: { channelId }
  })

export default ChannelUpdatedSubscription
