export const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export const isDevelopment = () =>
  process.env.REACT_APP_ENVIRONMENT === 'development'
