import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import MessengerIcon from 'components/icons/Messenger'
import CloseIcon from 'components/icons/Close'
import Messenger from 'components/Messenger'
import { inIframe } from 'utils'
import WidgetContext from 'components/WidgetContext'

const APP_QUERY = gql`
  query AppQuery($id: String!) {
    app(id: $id) {
      id
      hostname
    }
  }
`

const Widget = () => {
  const { appId } = useParams()
  const { data: { app: { hostname } = {} } = {}, loading } = useQuery(
    APP_QUERY,
    {
      variables: {
        id: appId
      }
    }
  )
  const { open, setOpen } = useContext(WidgetContext)

  useEffect(() => {
    const action = open ? 'open' : 'close'

    if (hostname) {
      window.parent.postMessage(
        {
          type: `annedesk:${action}Messenger`
        },
        hostname
      )
    }
  }, [open, hostname])

  useEffect(() => {
    if (inIframe() && hostname) {
      // validate the app id and get the hostname from the server

      window.parent.postMessage(
        {
          type: 'annedesk:load'
        },
        hostname
      )
    }
  }, [hostname])

  if (loading || !hostname) {
    return null
  }

  return (
    <div className="relative container flex flex-col items-end h-screen p-1">
      {open && <Messenger handleClose={() => setOpen(false)} />}
      <button
        className="absolute w-20 h-20 flex items-center justify-center bg-white hover:bg-purple-100 text-white font-bold py-2 px-4 rounded-full border border-purple-900 focus:outline-none right-0 bottom-0"
        onClick={() => setOpen(!open)}
      >
        <CloseIcon className={open ? '' : 'hidden'} />
        <MessengerIcon className={open ? 'hidden' : ''} />
      </button>
    </div>
  )
}

export default Widget
