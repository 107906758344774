import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { client } from 'config/apollo'
import { ApolloProvider } from '@apollo/react-hooks'
import { CurrentUserProvider } from 'components/CurrentUserContext'
import Widget from 'components/Widget'
// import ErrorBoundary from 'components/ErrorBoundary'
import { WidgetProvider } from 'components/WidgetContext'
import 'App.css'

const NotFound = () => null

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/:appId">
          <ApolloProvider client={client}>
            <CurrentUserProvider>
              <WidgetProvider>
                <Widget />
              </WidgetProvider>
            </CurrentUserProvider>
          </ApolloProvider>
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
