import React from 'react'

const PaperPlaneIcon = ({
  size = 32,
  style = {
    fill: 'none',
    stroke: '#fff',
    strokeLinecap: 'round',
    strokeMiterlimit: 10,
    strokeWidth: '32px'
  },
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 512 512"
    style={style}
    {...props}
  >
    <title>Paper-Plane</title>
    <path d="M53.12,199.94l400-151.39a8,8,0,0,1,10.33,10.33l-151.39,400a8,8,0,0,1-15-.34L229.66,292.45a16,16,0,0,0-10.11-10.11L53.46,215A8,8,0,0,1,53.12,199.94Z" />
    <line x1="460" y1="52" x2="227" y2="285" />
  </svg>
)

export default PaperPlaneIcon
